import React, { useMemo } from 'react';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import { useWindowSize } from 'react-use';
import BasePill from '@app/src/Components/Common/BasePill/BasePill';
import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import { categoryOverviewItemsSelector, useGetAllExpensesQuery } from '@app/src/api/expensesApi';
import { currencyWith0DecimalPlaces, isReactNative } from '@app/src/global/Helpers';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryPills.scss';

const ExpenseReviewCategoryPills = ({ expensesYear, loading, onClick = _.noop }) => {
  const { items, isSuccess: allExpensesIsSuccessful } = useGetAllExpensesQuery(
    {
      year: expensesYear
    },
    {
      selectFromResult: (result) => ({
        ...result,
        items: categoryOverviewItemsSelector(result)
      })
    }
  );

  const isLoading = useMemo(() => loading || !allExpensesIsSuccessful, [loading, allExpensesIsSuccessful]);

  return (
    <ExpenseReviewCategoryOverview
      items={items}
      loading={isLoading}
      onClick={onClick}
      ItemComponent={DeductionAmountItem}
    />
  );
};

const DeductionAmountItem = ({ categoryId, deductionAmount }) => (
  <BasePill icon={<ExpenseReviewCategoryIcon id={categoryId} size={16} />}>
    {currencyWith0DecimalPlaces(deductionAmount)}
  </BasePill>
);

const ExpenseReviewCategoryOverview = ({
  items = [],
  loading,
  numberOfLoadingItems = 10,
  maxPillsMobile = 4,
  maxPillsDesktop = 8,
  onClick,
  ItemComponent,
  getTruncatedLabel = (remainingItems) => {
    return `${remainingItems.length} More`;
  }
}) => {
  const maxPillCount = useMaxPillCount({ maxPillsMobile, maxPillsDesktop });

  const { truncatedItems, truncated, remainingItems } = useTruncatedItems(items, maxPillCount);

  if (loading) {
    return (
      <Stack direction='row' spacing={0.5} onClick={onClick}>
        {Array.from({ length: numberOfLoadingItems }).map((_val, index) => (
          <BasePill loading key={index} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction='row' spacing={0.5} onClick={onClick}>
      {truncatedItems.map((item, index) => {
        if (ItemComponent) {
          return <ItemComponent key={item.key ?? index} {...item} />;
        }

        return <BasePill key={index}>{item.label}</BasePill>;
      })}
      {truncated && (
        <BasePill>
          <div className='category-tile-text'>{getTruncatedLabel(remainingItems)}</div>
        </BasePill>
      )}
    </Stack>
  );
};

const useMaxPillCount = ({ maxPillsMobile, maxPillsDesktop }) => {
  const { width: windowWidth } = useWindowSize();

  // Control # of cats shown as a function of device width
  // For some reason, useWindowSize occasionally provides an inaccurate width in webview. For mobile, fix to 5 cats
  return useMemo(() => {
    if (isReactNative()) {
      return maxPillsMobile;
    }

    return _.min([maxPillsDesktop, windowWidth / 100]);
  }, [maxPillsDesktop, maxPillsMobile, windowWidth]);
};

const useTruncatedItems = (items, maxItems) => {
  const truncatedItems = useMemo(() => {
    if (_.size(items) <= maxItems) {
      return items;
    }

    return _.take(items, maxItems - 1);
  }, [items, maxItems]);

  const remainingItems = useMemo(() => {
    if (_.size(items) <= maxItems) {
      return [];
    }

    return _.slice(items, maxItems - 1);
  }, [items, maxItems]);

  const truncated = !_.isEmpty(remainingItems);

  return {
    truncatedItems,
    remainingItems,
    truncated
  };
};

export default ExpenseReviewCategoryPills;
