import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { setExpenses } from '@app/src/actions/expenseReviewActions';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { expensesSelector } from '@app/src/selectors/expenseReviewSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const expensesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseReviewDetails: builder.query({
      query: ({ year }) => ({
        url: 'expense/get-expense-review-details',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`
        }
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (_result, _error, { year }) => [{ type: TAGS.EXPENSE_REVIEW_DETAILS, id: year }]
    }),
    getAllExpenses: builder.query({
      query: ({ year }) => ({
        url: 'expense/search',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`,
          includeNotes: 1,
          includeJobPercentages: 1
        }
      }),
      transformResponse: (response) => response.data.expenses,
      providesTags: (_result, _error, { year }) => {
        return [{ type: TAGS.ALL_EXPENSES, id: year }];
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled, getState }) => {
        const result = await queryFulfilled;

        // Sync expenses in redux store
        const expenses = expensesSelector(getState());

        dispatch(
          setExpenses({
            ...expenses,
            expensesById: _.keyBy(result.data, 'transaction_id')
          })
        );
      }
    }),
    // workaround/temp solution to update expense data in cache when we fetch the data elsewhere
    updateExpenseDataCache: builder.mutation({
      queryFn: () => ({
        data: null
      }),
      invalidatesTags: [TAGS.SUBMIT_WARNINGS],
      onQueryStarted: async ({ transaction }, { dispatch }) => {
        const transactionId = transaction.transaction_id;
        const date = transaction.date;
        const year = moment(date).year().toString();

        dispatch(
          expensesApi.util.updateQueryData('getAllExpenses', { year }, (draftExpenses) => {
            const index = draftExpenses.findIndex((expense) => expense.transaction_id === transactionId);

            if (index !== -1) {
              draftExpenses[index] = transaction;
            } else {
              draftExpenses.push(transaction);
            }
          })
        );
      }
    })
  })
});

export const { useGetExpenseReviewDetailsQuery, useGetAllExpensesQuery } = expensesApi;

export const { updateExpenseDataCache } = expensesApi.endpoints;

export const getAllExpenses =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => expensesApi.endpoints.getAllExpenses.initiate({ year }),
      dispatch
    });

export const fetchExpenseReviewDetails =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => expensesApi.endpoints.getExpenseReviewDetails.initiate({ year }),
      dispatch
    });

export const allTaxYearExpensesSelector = (state) => {
  const year = yearSelector(state);
  const { data: expenses } = expensesApi.endpoints.getAllExpenses.select({ year })(state);
  return _.defaultTo(expenses, []);
};

export const unreviewedExpensesCountSelector = (state) => {
  const expenses = allTaxYearExpensesSelector(state);
  return _.chain(expenses)
    .filter(({ status }) => status === 'maybe')
    .size()
    .value();
};

const deductionsByCategoryIdSelector = createSelector(
  (result) => result.data,
  (expenses) => {
    return _.chain(expenses)
      .filter((expense) => expense.isDeductible)
      .groupBy('keeper_category_id')
      .value();
  }
);

export const categoryOverviewItemsSelector = createSelector(
  [deductionsByCategoryIdSelector],
  (deductionsByCategoryId) => {
    return _.chain(deductionsByCategoryId)
      .entries()
      .map(([categoryId, expenses]) => ({
        categoryId: Number(categoryId),
        count: expenses.length,
        deductionAmount: _.sumBy(expenses, 'deductionAmount'),
        key: categoryId
      }))
      .sortBy(({ deductionAmount }) => -deductionAmount)
      .value();
  }
);
