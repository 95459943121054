import _ from 'lodash';
import { createSelector } from 'reselect';
import { setSettings } from '@app/src/actions/authActions';
import { setExpensesYear } from '@app/src/actions/expenseReviewActions';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { SAVINGS_TO_DEDUCTIONS_ANNOUNCEMENT_VIEWED_CAMPAIGN } from '@app/src/constants/constants';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';

const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkInfo: builder.query({
      query: () => ({
        url: 'profile/workinfo',
        method: 'GET'
      }),
      providesTags: [TAGS.WORK_INFO],
      transformResponse: (response) => response.data
    }),
    getAppYears: builder.query({
      query: () => ({
        url: 'profile/app-years',
        method: 'GET'
      }),
      providesTags: [TAGS.APP_YEARS],
      transformResponse: (response) => response.data.years
    }),
    // deprecated -- use taxProfileApi.updateTaxProfile instead
    updateWorkInfo: builder.mutation({
      query: (body) => {
        return {
          url: 'profile/merge-workinfo',
          method: 'POST',
          body
        };
      },
      invalidatesTags: [TAGS.WORK_INFO, TAGS.TAX_ESTIMATE]
    }),
    getCampaign: builder.query({
      query: () => ({
        url: 'profile/get-campaign',
        method: 'GET'
      }),
      providesTags: [TAGS.CAMPAIGN],
      transformResponse: (response) => response.data.campaign
    }),
    updateCampaign: builder.mutation({
      query: ({ campaign }) => ({
        url: 'profile/update-campaign',
        method: 'POST',
        body: { campaign }
      }),
      invalidatesTags: [TAGS.CAMPAIGN]
    }),
    updateTestCampaigns: builder.mutation({
      query: ({ campaign }) => ({
        url: 'profile/update-test-campaigns',
        method: 'POST',
        body: {
          testCampaigns: {
            [campaign]: 1
          }
        }
      }),
      invalidatesTags: [TAGS.CAMPAIGN]
    }),
    // TODO: move all account details refs to make use of this endpoint
    // TODO: replace references to pricing with values from pricing/subscription-info
    getAccountDetails: builder.query({
      query: () => ({
        url: 'profile/account-details',
        method: 'GET'
      }),
      providesTags: [TAGS.ACCOUNT_DETAILS],
      transformResponse: (response) => response.data
    }),
    getRetroStatus: builder.query({
      query: ({ year }) => ({
        url: 'profile/run-retro',
        method: 'GET',
        params: { year }
      }),
      providesTags: (result, error, { year }) => [{ type: TAGS.RETRO_STATUS, id: _.toNumber(year) }],
      transformResponse: (response) => response.status
    }),
    getFeatures: builder.query({
      query: () => ({
        url: 'profile/features',
        method: 'GET'
      }),
      providesTags: [TAGS.FEATURES],
      transformResponse: (response) => response.data.features
    }),
    updateOnesignalDataTags: builder.mutation({
      query: ({ dataTags }) => ({
        url: 'profile/edit-data-tags',
        method: 'POST',
        body: { dataTags }
      })
    }),
    updateYearPreference: builder.mutation({
      query: ({ year }) => ({
        url: 'profile/year-preference',
        method: 'put',
        body: { year }
      }),
      onCacheEntryAdded: async ({ year }, { dispatch, getState }) => {
        const settings = userSettingsSelector(getState());

        dispatch(setSettings({ ...settings, year: parseInt(year) }));
        dispatch(setExpensesYear(year));
      }
    })
  })
});

export default profileApi;

export const {
  useGetWorkInfoQuery,
  useGetAccountDetailsQuery,
  useUpdateYearPreferenceMutation,
  useGetAppYearsQuery,
  // Used in actions/selectors
  useGetCampaignQuery,
  useUpdateTestCampaignsMutation,
  useGetRetroStatusQuery,
  useGetFeaturesQuery,
  useUpdateOnesignalDataTagsMutation
} = profileApi;

// Actions

export const getCampaign = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getCampaign.initiate, dispatch });

export const getRetroStatus =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => profileApi.endpoints.getRetroStatus.initiate({ year }),
      dispatch
    });

export const getFeatures = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: profileApi.endpoints.getFeatures.initiate, dispatch });

export const updateWorkInfo = (payload) => async (dispatch) => {
  await dispatch(profileApi.endpoints.updateWorkInfo.initiate(payload));
};

// Selectors
export const campaignSelector = createSelector(
  [profileApi.endpoints.getCampaign.select()],
  ({ data: campaign }) => campaign
);

export const featuresSelector = createSelector(
  [profileApi.endpoints.getFeatures.select()],
  ({ data: features }) => features || []
);

export const savingsToDeductionsAnnouncementViewedSelector = createSelector(
  (result) => result.data,
  (result) => result.isSuccess,
  (campaign, isSuccess) => {
    if (!isSuccess) {
      return true;
    }

    return _.get(campaign, ['test_campaigns', SAVINGS_TO_DEDUCTIONS_ANNOUNCEMENT_VIEWED_CAMPAIGN]) === 1;
  }
);

export const specificFeatureSelector = createSelector(
  [(result) => result.data, (result) => result.isSuccess, (_result, feature) => feature],
  (features, isSuccess, feature) => {
    if (!isSuccess) {
      return false;
    }
    return features.find((f) => f.name === feature)?.value === 1;
  }
);
