import React, { useEffect } from 'react';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import {
  setHolisticOnboardingSurvey,
  setHolisticOnboardingSurveyOptions
} from '@app/src/actions/holisticOnboardingActions';
import { useUpdateTestCampaignsMutation } from '@app/src/api/profileApi';
import { contentSelector, surveyOptionsSelector, surveySelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingSurvey = () => {
  const dispatch = useDispatch();

  const [updateTestCampaigns] = useUpdateTestCampaignsMutation();

  const {
    location: { pathname },
    push
  } = useHistory();

  const content = useSelector(contentSelector);
  const survey = useSelector(surveySelector);
  const surveyOptions = useSelector(surveyOptionsSelector);

  const options = content[pathname]?.options;

  useEffect(() => {
    const unshuffled = _.chain(options)
      .filter(({ shuffle }) => !shuffle)
      .map(({ text }) => text)
      .value();

    const shuffled = _.chain(options)
      .filter(({ shuffle }) => shuffle)
      .map(({ text }) => text)
      .shuffle()
      .value();

    dispatch(setHolisticOnboardingSurveyOptions([...shuffled, ...unshuffled]));
  }, [options, dispatch]);

  const handleSelect = async (value) => {
    dispatch(setHolisticOnboardingSurvey(value));

    await updateTestCampaigns({ campaign: 'self_attribution_survey_answered' });

    setTimeout(() => dispatch(onContinue({ push, pathname })), 500);
  };

  return (
    <Stack spacing={1}>
      {surveyOptions.map((text, idx) => (
        <ToggleButton key={idx} selected={survey === text} onClick={() => handleSelect(text)}>
          {text}
        </ToggleButton>
      ))}
    </Stack>
  );
};

export default OnboardingSurvey;
