import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import HolisticOnboardingLayout from '@app/src/Components/HolisticOnboarding/containers/HolisticOnboardingLayout';
import OnboardingAppDownload from '@app/src/Components/HolisticOnboarding/pages/OnboardingAppDownload';
import OnboardingBankLink from '@app/src/Components/HolisticOnboarding/pages/OnboardingBankLink';
import OnboardingDrive from '@app/src/Components/HolisticOnboarding/pages/OnboardingDrive';
import OnboardingGetStarted from '@app/src/Components/HolisticOnboarding/pages/OnboardingGetStarted';
import OnboardingHome from '@app/src/Components/HolisticOnboarding/pages/OnboardingHome';
import OnboardingInitial from '@app/src/Components/HolisticOnboarding/pages/OnboardingInitial';
import OnboardingJobDuration from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobDuration';
import OnboardingJobSelect from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobSelect';
import OnboardingLoadingInitial from '@app/src/Components/HolisticOnboarding/pages/OnboardingLoadingInitial';
import OnboardingMeal from '@app/src/Components/HolisticOnboarding/pages/OnboardingMeal';
import OnboardingPercentages from '@app/src/Components/HolisticOnboarding/pages/OnboardingPercentages';
import OnboardingPhone from '@app/src/Components/HolisticOnboarding/pages/OnboardingPhone';
import OnboardingSignup from '@app/src/Components/HolisticOnboarding/pages/OnboardingSignup';
import OnboardingSituations from '@app/src/Components/HolisticOnboarding/pages/OnboardingSituations';
import OnboardingSurvey from '@app/src/Components/HolisticOnboarding/pages/OnboardingSurvey';
import OnboardingTravel from '@app/src/Components/HolisticOnboarding/pages/OnboardingTravel';
import OnboardingVerify from '@app/src/Components/HolisticOnboarding/pages/OnboardingVerify';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import {
  ONBOARDING_BASE,
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHNAME_ONBOARDING__APP_DOWNLOAD_LINKED,
  PATHNAME_ONBOARDING__APP_DOWNLOAD_CHASE,
  PATHNAME_ONBOARDING__DRIVE,
  PATHNAME_ONBOARDING__GET_STARTED,
  PATHNAME_ONBOARDING__HOME,
  PATHNAME_ONBOARDING__JOB_DURATION,
  PATHNAME_ONBOARDING__JOB_SELECT,
  PATHNAME_ONBOARDING__LINK,
  PATHNAME_ONBOARDING__LOADING_INITIAL,
  PATHNAME_ONBOARDING__MEAL,
  PATHNAME_ONBOARDING__PERCENTAGES,
  PATHNAME_ONBOARDING__PHONE,
  PATHNAME_ONBOARDING__SIGNUP,
  PATHNAME_ONBOARDING__SITUATIONS,
  PATHNAME_ONBOARDING__TRAVEL,
  PATHNAME_ONBOARDING__VERIFY,
  PATHNAME_ONBOARDING__SURVEY
} from '@app/src/constants/onboardingConstants';
import { contentSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { initOnboarding, initPage } from '@app/src/services/holisticOnboardingService';

const routes = [
  { path: ONBOARDING_BASE, Component: OnboardingInitial },
  { path: PATHNAME_ONBOARDING__APP_DOWNLOAD, Component: OnboardingAppDownload },
  { path: PATHNAME_ONBOARDING__APP_DOWNLOAD_LINKED, Component: OnboardingAppDownload },
  { path: PATHNAME_ONBOARDING__APP_DOWNLOAD_CHASE, Component: OnboardingAppDownload },
  { path: PATHNAME_ONBOARDING__DRIVE, Component: OnboardingDrive },
  { path: PATHNAME_ONBOARDING__HOME, Component: OnboardingHome },
  { path: PATHNAME_ONBOARDING__GET_STARTED, Component: OnboardingGetStarted },
  { path: PATHNAME_ONBOARDING__JOB_DURATION, Component: OnboardingJobDuration },
  { path: PATHNAME_ONBOARDING__JOB_SELECT, Component: OnboardingJobSelect },
  { path: PATHNAME_ONBOARDING__LINK, Component: OnboardingBankLink },
  { path: PATHNAME_ONBOARDING__LOADING_INITIAL, Component: OnboardingLoadingInitial },
  { path: PATHNAME_ONBOARDING__PERCENTAGES, Component: OnboardingPercentages },
  { path: PATHNAME_ONBOARDING__MEAL, Component: OnboardingMeal },
  { path: PATHNAME_ONBOARDING__PHONE, Component: OnboardingPhone },
  { path: PATHNAME_ONBOARDING__SIGNUP, Component: OnboardingSignup },
  { path: PATHNAME_ONBOARDING__SITUATIONS, Component: OnboardingSituations },
  { path: PATHNAME_ONBOARDING__SURVEY, Component: OnboardingSurvey },
  { path: PATHNAME_ONBOARDING__TRAVEL, Component: OnboardingTravel },
  { path: PATHNAME_ONBOARDING__VERIFY, Component: OnboardingVerify },
  { path: '*', Component: () => <Redirect to={ONBOARDING_BASE} /> }
];

const HolisticOnboardingApp = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;

  const [onboardingInitialized, setOnboardingInitialized] = useState(false);

  const content = useSelector(contentSelector);

  useEffect(() => {
    if (_.isEmpty(content) && !onboardingInitialized) {
      dispatch(initOnboarding({ history, location }));
      setOnboardingInitialized(true);
    } else if (!_.isEmpty(content)) {
      dispatch(initPage({ pathname }));
    }
  }, [content, dispatch, history, location, onboardingInitialized, pathname]);

  const renderRoute = useCallback(
    () => (
      <StripeProvider>
        <HolisticOnboardingLayout>
          <Switch location={location}>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path} component={Component} />
            ))}
          </Switch>
        </HolisticOnboardingLayout>
      </StripeProvider>
    ),
    [location]
  );

  return <Route render={renderRoute} />;
};

export default HolisticOnboardingApp;
